var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"registration__inner crazy-dragon"},[_c('div',{staticClass:"registration__item"},[_c('h2',{staticClass:"registration__title"},[_vm._v(_vm._s(_vm.content.title))]),_c('div',{staticClass:"registration__text",domProps:{"innerHTML":_vm._s(_vm.content.block[0])}})]),_c('div',{staticClass:"registration__item"},[_c('div',{staticClass:"form-block"},[_c('h3',{staticClass:"form-block__title"},[_vm._v("Login")]),(_vm.text)?_c('Alert',{attrs:{"variant":_vm.variant}},[_vm._v(_vm._s(_vm.text))]):_vm._e(),_c('div',{staticClass:"form-block__body"},[_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"login","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"form-row",class:{ 'has-error': failed }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.login),expression:"form.login"}],staticClass:"form-control",attrs:{"name":"login","type":"text","placeholder":"Email"},domProps:{"value":(_vm.form.login)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "login", $event.target.value)}}}),_c('span',{staticClass:"help-message"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"form-row",class:{ 'has-error': failed }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",attrs:{"name":"password","type":"password","placeholder":"Password"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('span',{staticClass:"help-message"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('div',{staticClass:"form-bottom"},[_vm._v(" You forgot password? "),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'forgotPassword' }}},[_vm._v(" Forgot password ")])],1),_c('div',{staticClass:"btns-group--right"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v("Login")])])],1)]}}])})],1),_c('hr',{staticClass:"divider-text"}),_c('div',{staticClass:"social-signin"},[_c('ButtonFacebook'),_c('ButtonGoogle')],1),_c('hr',{staticClass:"divider"}),_c('div',{staticClass:"form-bottom"},[_vm._v(" You don‘t have an account? "),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'register' }}},[_vm._v("Register here")])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }