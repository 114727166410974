<template>
  <div class="container">
    <div class="registration__inner crazy-dragon">
      <div class="registration__item">
        <h2 class="registration__title">{{content.title}}</h2>
        <div class="registration__text" v-html="content.block[0]"></div>
      </div>
      <div class="registration__item">
        <div class="form-block">
          <h3 class="form-block__title">Login</h3>
          <Alert :variant="variant" v-if="text">{{ text }}</Alert>
          <div class="form-block__body">
            <ValidationObserver slim v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)">
                <ValidationProvider name="login" rules="required|email" v-slot="{ errors, failed }">
                  <div class="form-row" :class="{ 'has-error': failed }">
                    <input
                      name="login"
                      v-model="form.login"
                      class="form-control"
                      type="text"
                      placeholder="Email"
                    />
                    <span class="help-message">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  name="password"
                  rules="required|min:6"
                  v-slot="{ errors, failed }"
                >
                  <div class="form-row" :class="{ 'has-error': failed }">
                    <input
                      name="password"
                      v-model="form.password"
                      class="form-control"
                      type="password"
                      placeholder="Password"
                    />
                    <span class="help-message">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <div class="form-bottom">
                  You forgot password?
                  <router-link class="link" :to="{ name: 'forgotPassword' }">
                    Forgot password
                  </router-link>
                </div>
                <div class="btns-group--right">
                  <button type="submit" class="btn btn-primary">Login</button>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <hr class="divider-text" />
          <div class="social-signin">
            <ButtonFacebook />
            <ButtonGoogle />
          </div>
          <hr class="divider" />
          <div class="form-bottom">
            You don‘t have an account?
            <router-link class="link" :to="{ name: 'register' }">Register here</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import autorization from '@/mixins/autorization';
import alert from '@/mixins/alert';
import content from '@/mixins/content';

import AutorizationService from '@/services/AutorizationService';

import ButtonFacebook from '@/components/buttons/ButtonFacebook.vue';
import ButtonGoogle from '@/components/buttons/ButtonGoogle.vue';
import Alert from '@/components/Alert.vue';

export default {
  mixins: [autorization, alert, content],
  components: {
    ButtonFacebook,
    ButtonGoogle,
    Alert,
  },
  data() {
    return {
      form: {
        login: null,
        password: null,
      },
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.removeAlert();
        const { headers } = await AutorizationService.login(this.form);

        if (headers['x-api-key']) {
          this.setAutorization(headers);
        } else {
          document.querySelector('#app').scrollIntoView(true);
          this.setAlert({ textArg: 'Server Error: Try again !', variantArg: 'danger' });
        }
      } catch (e) {
        document.querySelector('#app').scrollIntoView(true);
        if (e.response.data.message) {
          this.setAlert({ textArg: e.response.data.message, variantArg: 'danger' });
        } else {
          this.setAlert({ textArg: e.response.data[0].message, variantArg: 'danger' });
        }
      }
    },
  },
  mounted() {
    document.body.classList.add('page-layout');
    document.body.style.background = '';
  },
};
</script>
